<template>
  <div class="majorIntroduce">
<!--    <h3 class="back"> <span @click="$router.back()">返回</span> < {{item.name_chs}} </h3>-->
    <p class="back">
      <a href="/">首页&nbsp;>&nbsp;</a>
      <a @click="$router.push({path:'/schools/schoolIntroduce',query:{id:item.school.id}})">{{ item.school.name_chs }}&nbsp;>&nbsp;</a>
      <a href="##">&nbsp;{{item.name_chs}}&nbsp;</a>
    </p>
    <div class="intro">
      <div class="intro-left">
        <div class="school">
          <p style="margin-bottom: 0">{{ item.name_chs }}</p>
          <p>{{ item.name_eng }}</p>
        </div>
        <div>
          <div>
            <div style="text-align: center">
<!--              <img width="100%" :src="item.cooperative_university.logo" alt="">-->
              <img style="margin-top: 1vw;width: 85%" :src="item.has_cooper_university?item.cooperative_university.logo:item.school.school_logo" alt="">
            </div>
            <div class="text">{{ item.subject_introduction }}</div>
          </div>
        </div>
        <div class="allInfo">
          <div class="title">课程概述</div>
          <div class="allShow">
            <div class="info1">
              <img src="../../public/assets/img/217时间.png" alt="">
              <strong>课程时长：</strong>
              <span>{{ item.subject_duration }}月</span>
            </div>

            <div class="info1">
              <img src="../../public/assets/img/等级.png" alt="">
              <strong>课程等级：</strong>
              <span>{{ item.subject_level.name }}</span>
            </div>

            <div class="info1">
              <img src="../../public/assets/img/其他费用.png" alt="">
              <strong>课程费用：</strong>
              <span>S${{item.subject_fee }}（约人民币{{ myMoney }}）</span>
            </div>

            <div class="info1">
              <img src="../../public/assets/img/日期.png" alt="">
              <strong>截止日期：</strong>
              <span>{{ item.apply_deadline }}</span>
            </div>
          </div>
        </div>
        <div class="cooper" v-if="item.has_cooper_university">
          <div class="school">
            <p>合作大学-{{ item.cooperative_university.name_chs }}</p>
            <p>{{ item.cooperative_university.name_eng }}</p>
          </div>
          <div>
            <div style="text-align:center">
              <img style="margin:1vw auto 0;width: 85%" :src="item.cooperative_university.logo" alt="">
            </div>
            <div class="rank">
              <div class="rankSon">
                <div class="rankImg">
                  <img width="100%" height="100%" src="../../public/assets/img/qs.jpeg" alt="">
                </div>
                <div>
                  <div>QS 排名</div>
                  <div>{{item.cooperative_university.qs_rank}}</div>
                </div>
              </div>

              <div class="rankSon">
                <div class="rankImg">
                  <img width="100%" height="100%" src="../../public/assets/img/times.png" alt="">
                </div>
                <div>
                  <div>TIMES 排名</div>
                  <div>{{item.cooperative_university.times_rank}}</div>
                </div>
              </div>
            </div>
            <div class="text">{{ item.cooperative_university.introduction }}</div>
          </div>
        </div>
        <div class="cooper" v-else>
          <h4>合作大学：{{ item.has_cooper_university?item.cooperative_university.name_chs:'暂无'}}</h4>
        </div>
        <div class="imgType" v-if="item.has_cooper_university">
          <img width="100%" :src="item.cooperative_university.china_moe_certified_image" alt="">
        </div>
      </div>
      <div class="intro-right">
        <div class="require">
          <div style="font-weight:bold;font-size:1vw">申请要求</div>
          <div>{{ item.apply_requirement }}</div>
          <div style="text-align: left;margin-top: 2vw"><strong>开始时间：</strong>{{ item.subject_start_date }}</div>
          <div style="text-align: left"><strong>申请截止：</strong>{{ item.apply_deadline }}</div>
        </div>
        <div class="formBd">
          <consultantForm></consultantForm>
        </div>
        <div class="consultant">
          <div class="guwen">了解更多详情，请咨询下方顾问老师</div>
          <div class="avatar">
            <img width="100%" :src="lizi.avatar" alt="">
          </div>
          <div class="guName">
            <div>{{lizi.name}}</div>
            <div>高等教育事业部</div>
          </div>
          <div class="qr">
            <img width="100%" :src="lizi.wechat_qr" alt="">
          </div>
          <div class="guName">微信号：{{lizi.wechat_id}}</div>
        </div>
      </div>
    </div>

    <div>
      <div>
        <div class="more">发现更多专业</div>
        <div class="changeOnePi" @click="getSimilar">换一批</div>
      </div>
      <div class="similar">
        <majorCard
            style="border: 2px solid whitesmoke"
            v-for="item in similarSubjects"
            :key="item.id"
            :item="item"></majorCard>
      </div>
    </div>
  </div>
</template>

<script>
import {httpService} from "@/services/http";
import {constants} from "@/services/constants";
import {schoolService} from "@/services/school";
import consultantForm from "@/components/ConsultantForm";
import majorCard from "@/components/majorCard/majorCard";

export default {
  name: "majorIntroduce",
  components:{
    consultantForm,
    majorCard
  },
  data(){
    return{
      item:'',
      myMoney:'',
      similarSubjects:[],
      all:[],
      lizi:{},
      schoolId:''
    }
  },
  created() {
    this.item = JSON.parse(this.$route.query.item)
    if (this.item.subject_fee * 5 < 10000){
      this.myMoney = this.item.subject_fee * 5 + '元'
    }else {
      this.myMoney =( this.item.subject_fee * 5/10000).toFixed(1) + '万'
    }
    console.log(this.item)
    this.schoolId = this.item.school.id
    this.getSchool()
    this.getSimilar()
    this.getConsultant()
  },
  methods:{
    getSchool(){
      schoolService.getSchoolData(this.schoolId).then((res) => {
        this.lizi = res.consultant
        console.log(res)
      })
    },
    getConsultant(){
      httpService.get(constants.sagepathsAgent).then((res) => {
        this.lizi = res.data.results[0]
      })
    },
    getSimilar(){
      schoolService.getAllSubjects(`/?subject_level__name=${this.item.subject_level.name}`).then((res) => {
        this.similarSubjects = this.getRandomArrayElements(res.results,4)
      })
    },

    /* 随机获取数组中的数据*/
    getRandomArrayElements(arr, count) {
      let shuffled = arr.slice(0),
          i = arr.length,
          min = i - count,
          temp, index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },
  },
  watch: {
    $route: {
      handler: function(val, oldVal){
        this.item = JSON.parse(this.$route.query.item)
      },
      // 深度观察监听
      deep: true
    }
  },
}
</script>

<style scoped lang="scss">
//*{
//  outline: 1px solid red;
//}
.consultant{
  border: 2px solid whitesmoke;
  margin-top: 3vw;
  border-radius: 10px;
  padding:2vw;
  text-align: center;
  .guwen{
    font-size: 1vw;
    font-weight: bold;
  }
}
.imgType{
  //text-align: center;
  //border: 2px solid whitesmoke;
  width: 100%;
  padding: 0 1vw 2vw;
}
.similar{
  display: flex;
  justify-content: space-between;
}
.more{
  text-align: center;
  font-weight: bold;
  font-size: 2vw;
  margin: 2vw 0 0;
}
.changeOnePi{
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 1vw;
}
.majorIntroduce{
  width: 80%;
  margin: 0 auto;
}
.back{
  margin-top: 2vw;
  cursor: pointer;
  font-weight: bold;
  font-size: 1vw;
}
.text{
  margin: 1vw 0;
  font-size: .8vw;
  padding: 0 1vw;
}
.rank{
  margin: 1vw auto;
  font-size: 1vw;
  font-weight: bold;
  width: 85%;
  display: flex;
  justify-content: space-between;
}
.rankSon{
  display: flex;
  align-items: center;
  margin-top: 1vw;
}
.rankImg{
  width: 15vw;
  height: 8vw;
  padding-right: 1vw;
}
.intro{
  padding: 2vw 1vw;
  display: flex;
  justify-content: space-between;
  .intro-left{
    width: 68%;
    //background: #007DD11A;
    background: whitesmoke;
    border-radius: 10px;
    padding-top: 2vw;
    .school{
      font-size: 2vw;
      font-weight: bold;
      p{
        text-align: center;
      }
    }
    .allInfo{
      //background: ghostwhite;
      padding: 1vw 1vw 2vw;
      .title{
        font-weight: bold;
        font-size: 1.1vw;
      }
      .allShow{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .info1{
          width: 45%;
          margin-top: 2vw;
          font-size: 1vw;
          img{
            width: 1.8vw;
            margin-right: .5vw;
          }
        }
      }
    }
    .cooper{
      margin-top: 2vw;
      //background: #007DD11A;
      padding: 1vw;
      .schoolImg{
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
          margin: 0 auto;
        }
      }
      .text{
        margin-top: 1vw;
      }
      .pai{
        display: flex;
        justify-content: space-around;
        margin-top: 1vw;
      }
    }
  }
  .intro-right{
    width: 30%;
    .require{
      //background: #007DD11A;
      background: whitesmoke;
      border-radius: 10px;
      text-align: center;
      padding: 2vw;
      min-height: 20vw;
      border: 2px solid whitesmoke;
      font-size: .8vw;
    }
    .formBd {
      width: 100%;
      border-radius: 10px;
      //box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      text-align: center;
      margin: 3vw 2vw 0 0;
      outline: 2px solid whitesmoke;
    }
  }
}
.avatar{
  //border-radius: 50%;
  width: 7vw;
  margin: 1vw auto;
  img{
    border-radius: 50%;
  }
}
.guName{
  font-weight: bold;
  font-size: 1vw;
}
.qr{
  width: 10vw;
  margin: 0 auto;
}
</style>
